import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { PageNotFoundComponent } from '@insureshop/shared/components/404/page-not-found.component';
import { MaintenanceComponent } from '@insureshop/shared/components/maintenance/maintenance.component';
import { PublicLayoutComponent } from '@insureshop/shared/components/layout/base/public-layout.component';

const routes: Routes = [
  {
    path: '', component: PublicLayoutComponent,
    children: [
      {
        path: '',
        loadChildren: './public/public.module#PublicModule'
      },
      {
        path: 'travel',
        data: { title: 'Travel Insurance' },
        loadChildren: './modules/insurance/travel/travel-insurance.module#TravelInsuranceModule'
      },
      {
        path: 'health',
        data: { title: 'Health Insurance' },
        loadChildren: './modules/insurance/health-accident/health-insurance.module#HealthInsuranceModule'
      },
      {
        path: 'motor/ridesure',
        data: { title: 'Motor Insurance' },
        loadChildren: './modules/insurance/motor/motor-insurance.module#MotorInsuranceModule'
      },
      {
        path: 'motor/motor-car-comprehensive',
        data: { title: 'Motor Car Insurance' },
        loadChildren: './modules/insurance/motor-car/motor-car-insurance.module#MotorCarInsuranceModule'
      },
      {
        path: 'pay',
        data: { title: 'Payment' },
        loadChildren: './modules/payment/payment.module#PaymentModule'
      },
      {
        path: 'user',
        data: { title: 'User' },
        loadChildren: './modules/user/user.module#UserModule'
      },
      {
        path: 'claim',
        data: { title: 'Claim' },
        loadChildren: './modules/claim/claim.module#ClaimModule'
      },
      {
        path: 'admin',
        data: { title: 'Admin' },
        loadChildren: './modules/admin/admin.module#AdminModule'
      },
      { path: 'motor', redirectTo: '/motor/ridesure' }
    ]
  },
  { path: '**', component: PageNotFoundComponent }
  /*{ path: '**', component: MaintenanceComponent }*/
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule {}
