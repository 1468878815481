import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-maintenance',
  templateUrl: './maintenance.component.html',
  styleUrls: ['./maintenance.component.scss']
})
export class MaintenanceComponent implements OnInit {

  public admin = false;

  constructor(private route: ActivatedRoute) { }

  ngOnInit() {
    console.log('on init called');
    window.scroll(0, 0);

    /*if (this.route.snapshot.queryParamMap.has('admin')) {
      this.admin = true;
    }*/
  }
}
